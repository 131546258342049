$(function () {
  $("#nav-hamburger").click(function () {
    $("#nav-product-category-list").toggleClass("product-category-list--active");
  });
  $(document).click(function (event) {
    var $target = $(event.target);
    // 關閉漢堡選單
    if (
      !$target.closest("#nav-product-category-list").length 
      && !$target.closest("#nav-hamburger").length 
      && !$target.is("#nav-product-category-list") 
      && !$target.is("#nav-hamburger") 
      && $("#nav-product-category-list").hasClass("product-category-list--active")) {
        $("#nav-product-category-list").removeClass("product-category-list--active");
    }
    // 關閉會員選單
    if (
      !$target.closest("#nav-mobile-list").length
      && !$target.closest("#nav-user").length
      && !$target.is("#nav-mobile-list")
      && !$target.is("#nav-user")) {
        $("#nav-mobile-list").slideUp();
    }
  });
  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    if (scrollTop > 0) {
      $("#nav").addClass("scrolling");
      $("#nav-mobile-list").addClass("scrolling");
    } else {
      $("#nav").removeClass("scrolling");
      $("#nav-mobile-list").removeClass("scrolling");
    }
  });
  $("#nav-user").click(function () {
    $("#nav-mobile-list").slideToggle();
  });
  $("#nav-mobile-list").find(".nav-mobile-list__item").click(function () {
    $("#nav-mobile-list").slideToggle();
  });
  $(".input-stepper__increment").click(function () {
    var input = $(this).closest(".input-stepper").find(".input-stepper__input");
    input.val(parseInt(input.val()) + 1);
  });
  $(".input-stepper__decrement").click(function () {
    var input = $(this).closest(".input-stepper").find(".input-stepper__input");
    if (parseInt(input.val()) > 1) {
      input.val(parseInt(input.val()) - 1);
    }
  });
  $(".modal__close").click(function () {
    $(this).closest(".modal").addClass("modal--hide");
  });
  // 註冊/登入
  $("#open-sign-button-mobile, #open-sign-button-desktop, #open-sign-button-footer").click(function () {
    $("#sign").removeClass("modal--hide").hide().fadeIn();
    $("#sign-up").removeAttr("style");
    $("#sign-in").removeAttr("style");
  });
  $("#sign-up-to-sign-in").click(function () {
    $("#sign-up").hide()
    $("#sign-in").fadeIn();
  });
});